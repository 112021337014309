/********************************************************************
* DXバナー
**********************************************************************/
const floatBanner = () => {
    const target = document.querySelector('.js_floatBanner');
    if (!target) return;

    let destory = false;
    const close = target.querySelector('.js_floatBanner_close');
    const footer = document.querySelector('.ly_footer');

    // スクロールしたらバナーを表示
    const doWhenScroll = () => {
        if (destory) return;
        
        const scroll = window.scrollY;
        const targetPos = 700;
        const endPos = footer.getBoundingClientRect().top + scroll - window.innerHeight;
    
        if (scroll > targetPos && scroll < endPos) {
            target.classList.add('is_show');
        } else {
            target.classList.remove('is_show');
        }
    }
    window.addEventListener('scroll', doWhenScroll);

    // 閉じるボタンをクリックしたらバナーを閉じる
    const doWhenClickClose = () => {
        destory = true;
        target.classList.remove('is_show');
    }
    close.addEventListener('click', doWhenClickClose);
}

export default floatBanner;